import { PermissionLevel } from '@/utils/permission';

export const cbsRoutes = [
  {
    component: () => import('@/pages/cbs/import/invoices/index.vue'),
    meta: { title: 'menu_cf_import_invoices' },
    name: 'Import Invoices',
    path: 'importinvoices'
  },
  {
    component: () => import('@/pages/cbs/import/surcharges/index.vue'),
    meta: { title: 'menu_cf_import_surcharges' },
    name: 'Import Surcharges',
    path: 'importsurcharges'
  },
  {
    component: () => import('@/pages/cbs/invoicedatacheck/index.vue'),
    meta: { title: 'menu_cf_invoice_data_check' },
    name: 'Invoice data check',
    path: 'invoicedatachecks'
  },
  {
    component: () => import('@/pages/cbs/export/index.vue'),
    meta: { title: 'menu_cf_export' },
    name: 'Upload Invoices and Surcharges',
    path: 'exportinvoices'
  }
];

export const cbsRoutesAdmin = [
  {
    component: () => import('@/pages/admin/hermesinvoicemapping/index.vue'),
    meta: {
      permissions: [{ HermesInvoiceMap: PermissionLevel.Read }],
      title: 'menu_hermes_invoice_mapping'
    },
    name: 'HermesInvoiceMapping',
    path: 'hermes-invoice-mapping'
  },
  {
    component: () => import('@/pages/admin/hermesinvoicemapping/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesInvoiceMap: PermissionLevel.Write }],
      title: 'createHermesInvoiceMap'
    },
    name: 'CreateHermesInvoiceMap',
    path: 'hermes-invoice-mapping/create'
  },
  {
    component: () => import('@/pages/admin/hermesinvoicemapping/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesInvoiceMap: PermissionLevel.Read }],
      title: 'editHermesInvoiceMap'
    },
    name: 'EditHermesInvoiceMap',
    path: 'hermes-invoice-mapping/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/hermesclientrates/index.vue'),
    meta: {
      permissions: [{ HermesClientRate: PermissionLevel.Read }],
      title: 'menu_hermes_client_rates'
    },
    name: 'HermesClientRates',
    path: 'hermes-client-rates'
  },
  {
    component: () => import('@/pages/admin/hermesclientrates/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesClientRate: PermissionLevel.Write }],
      title: 'createHermesClientRate'
    },
    name: 'CreateHermesClientRate',
    path: 'hermes-client-rates/create'
  },
  {
    component: () => import('@/pages/admin/hermesclientrates/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesClientRate: PermissionLevel.Read }],
      title: 'editHermesClientRate'
    },
    name: 'EditHermesClientRate',
    path: 'hermes-client-rates/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/hermeszones/index.vue'),
    meta: {
      permissions: [{ HermesZone: PermissionLevel.Read }],
      title: 'menu_hermes_zones'
    },
    name: 'HermesZones',
    path: 'hermes-zones'
  },
  {
    component: () => import('@/pages/admin/hermeszones/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesZone: PermissionLevel.Write }],
      title: 'createHermesZone'
    },
    name: 'CreateHermesZone',
    path: 'hermes-zones/create'
  },
  {
    component: () => import('@/pages/admin/hermeszones/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesZone: PermissionLevel.Read }],
      title: 'editHermesZone'
    },
    name: 'EditHermesZone',
    path: 'hermes-zones/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/hermesproducts/index.vue'),
    meta: {
      permissions: [{ HermesProduct: PermissionLevel.Read }],
      title: 'menu_hermes_products'
    },
    name: 'HermesProducts',
    path: 'hermes-products'
  },
  {
    component: () => import('@/pages/admin/hermesproducts/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesProduct: PermissionLevel.Write }],
      title: 'createHermesProduct'
    },
    name: 'CreateHermesProduct',
    path: 'hermes-products/create'
  },
  {
    component: () => import('@/pages/admin/hermesproducts/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesProduct: PermissionLevel.Read }],
      title: 'editHermesProduct'
    },
    name: 'EditHermesProduct',
    path: 'hermes-products/edit/:id(\\d+)'
  },
  {
    component: () => import('@/pages/admin/hermesclients/index.vue'),
    meta: {
      permissions: [{ HermesClient: PermissionLevel.Read }],
      title: 'menu_hermes_clients'
    },
    name: 'HermesClients',
    path: 'hermes-clients'
  },
  {
    component: () => import('@/pages/admin/hermesclients/create.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesClient: PermissionLevel.Write }],
      title: 'createHermesClient'
    },
    name: 'CreateHermesClient',
    path: 'hermes-clients/create'
  },
  {
    component: () => import('@/pages/admin/hermesclients/edit.vue'),
    hidden: true,
    meta: {
      permissions: [{ HermesClient: PermissionLevel.Read }],
      title: 'editHermesClient'
    },
    name: 'EditHermesClient',
    path: 'hermes-clients/edit/:id(\\d+)'
  }
];
