import axios from 'axios';
// import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
import ActivityBasedTimer from '@/utils/browser/ActivityBasedTimer';
export const baseURL = window.API_URL || process.env.API_URL;
// create an axios instance
const service = axios.create({
  baseURL,
  timeout: 30000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  async config => {
    // Refresh interval timers
    ActivityBasedTimer.runTimersCheck();

    const store = (await import('@/store/index.ts')).default; // store must be loaded lazy to that no circular import occurs
    if (store.getters.token) {
      // Let each request carry token
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    if (store.getters.language) {
      config.headers['Accept-Language'] = store.getters.language;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => response,
  async error => {
    const store = (await import('@/store/index.ts')).default;
    store.dispatch('request/handleRequestError', error);
    return Promise.reject(error);
  }
);

export default service;
