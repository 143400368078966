import request from '@/utils/request';

function transformItem2Dto(item) {
  const dto = {
    ...item
  };
  dto['surchargeID'] = item.id;
  delete dto.id;
  return dto;
}

function transformDto2Item(dto) {
  const item = {
    ...dto,
    id: dto['surchargeID']
  };
  delete item['surchargeID'];
  return item;
}

export const apiOverrides = { transformItem2Dto, transformDto2Item };

export const storeExtensions = {
  actions: {
    generateExcelExport({ dispatch }, hermesInvoiceNumber) {
      return new Promise((resolve, reject) =>
        generateExcelExport(hermesInvoiceNumber)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          })
      );
    }
  },
  getters: {},
  mutation: {},
  state: {}
};

function generateExcelExport(hermesInvoiceNumber) {
  return request({
    method: 'get',
    url: 'surcharge/export',
    params: {
      hermesInvoiceNumber: hermesInvoiceNumber
    },
    responseType: 'blob',
    timeout: 120000
  });
}
